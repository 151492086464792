<!--<ul class="background">-->
  <div class="container">

    <div class="grid" style="border-bottom: 2.5px inset #a6445e;">
      <div>
        <h1 class="recently-header" style="text-decoration: underline; text-decoration-color: #a6445e;">Hi there,</h1>
        <p class="info" style="vertical-align: middle; color: #eaeaea;">I'm James a Software Engineer on the hunt for<br /> my next role in Melbourne, Australia. </p>
      </div>
      <div style="text-align: right; margin: auto;" class="menu-desktop">
        <a style="color: #eaeaea;" class="link" target="_blank" href="mailto:someone@yoursite.com">Walshe97@gmail.com</a>
        <a style="color: #eaeaea;" class="link" target="_blank" href="https://www.linkedin.com/in/james-walshe-265b751b7/"><p>LinkedIn</p></a>
        <a style="color: #eaeaea;" class="link" target="_blank" href="assets/James%20Walshe%20-%20CV%202024.pdf"><p>Full Resume</p></a>
      </div>

      <div style="text-align: left; margin: auto;" class="menu-mobile">
        <a style="color: #eaeaea;" class="link" target="_blank" href="mailto:someone@yoursite.com">Walshe97@gmail.com</a>
        <a style="color: #eaeaea;" class="link" target="_blank" href="https://www.linkedin.com/in/james-walshe-265b751b7/"><p>LinkedIn</p></a>
        <a style="color: #eaeaea;" class="link" target="_blank" href="assets/James%20Walshe%20-%20CV%202024.pdf"><p>Full Resume</p></a>
      </div>
    </div>

    <div class="grid">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px; ">Career >></span>  </p>
      <p class="info">I've just finished two years at Kainos Software in Belfast working on a UK government project maintaining the login system
        for the justice department. I also ran a web design business for a year just after I graduated.</p>
    </div>

    <div class="grid" style="justify-content: left;">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Education >></span></p>
      <p class="info">I completed an MSc in Software Development at Queens' University Belfast achieving a 2.1 grade. My dissertation was a
        web scraper and data visualisation website.</p>
    </div>

    <div class="grid-container">
      <div class="grid-item">
        <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Key Skills >></span></p>
      </div>
      <div class="grid-item">
        <span class="image-container"><img style="width: 150px" src="assets/angular.png"></span>
        <span class="image-container"><img style="width: 150px" src="assets/java.png"></span>
      </div>
      <div class="grid-item">
        <span class="image-container"><img style="width: 150px" src="assets/docker.png"></span>
        <span class="image-container"><img style="width: 150px" src="assets/javascript.png"></span>
      </div>
      <div class="grid-item">
        <span class="image-container"><img style="width: 150px" src="assets/ansible.png"></span>
        <span class="image-container"><img style="width: 150px" src="assets/karma.png"></span>
      </div>
      <div class="grid-item">
        <span class="image-container"><img style="width: 150px" src="assets/git.png"></span>
        <span class="image-container"><img style="width: 150px" src="assets/jira.png"></span>
      </div>
    </div>

    <span class="grid-container-mobile">
      <div style="width: 49%;">
        <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Key Skills >></span></p>
      </div>
      <div class="grid">
        <p>
          <li class="info">Angular</li>
          <li class="info">Ansible</li>
          <li class="info">Java</li>
          <li class="info">Docker</li>
          <li class="info">Javascript</li>
          <li class="info">HTML</li>
          <li class="info">Agile</li>
          <li class="info">Jira</li>
          <li class="info">Git</li>
        </p>
      </div>
    </span>


    <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Projects >></span></p>

    <div class="grid">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Idam //</span><br />Kainos</p>
      <p class="info">My first professional project, Idam is an identity accesss management system used by a department of the UK government. It posed a significant challenge due to my
      lack of experience with a number of the technologies used and to the complexity of the system. Through external courses, peer mentoring and perseverance, I becamce
      comfortable with the system and contributed to tickets using a broad range of technologies.</p>
    </div>

    <div class="grid">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">CarCompare //</span><br />Dissertation</p>
      <p class="info">For my masters dissertation I created a tool that allowed users to view real-time price breakdowns for second hand cars in Ireland. Data was
      scraped from a second hand car website and displayed in various charts and graphs. Users could view data for specific models or years, and breakdown price according to location.
      I used Python to web scrape, PHP to build my API, HTML and CSS for the frontend and Google Javascript API's for the charts and graphs. The website is available
      <a style="color: white" target="_blank" href="https://jameswalshe.tech/CarCompare/">here.</a></p>
    </div>

    <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">References >></span></p>

    <div class="grid">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Ravneet Singh //</span><br />Project Manager</p>
      <p class="info">"I have been super impressed with James' work ethic - he is clearly very passionate for his work and eager to learn which is
        shown by his willingness to go on multiple courses to increase his knowledge. James is proactive in asking for new work to take on and has
        shown his ability to take on multiple tasks at the same time showing great time management skills. This proactive and diligent work ethic
        has not gone unnoticed - this has been called out for praise by senior members of the team and by the client."</p>
    </div>

    <div class="grid">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Lukasz Korzybski //</span><br />Solution Architect</p>
      <p class="info">"James has joined Crime IDAM team as a trainee engineer, due to the nature of the project he jumped onto an accelerated
        learning process with a massive amount of technology to learn. James has done an amazing progress over the last year. As expected for anyone,
        James needed significant amount of help in order to complete his stories, one year forward and James is a very much capable team member able
        to deliver stories of various complexities that touch various technologies."</p>
    </div>

    <div class="grid" style="margin-bottom: 100px">
      <p class="recently"><span style="text-decoration: underline; text-decoration-color: #a6445e; text-decoration-thickness: 2.5px;">Manpreet Mandair //</span><br />Software Engineer</p>
      <p class="info">"Since joining the HMCTS IdAM project, James has been incredibly helpful in ensuring I get settled in.
        He has gone above and beyond in supporting me through the lengthy onboarding process, walking me through my early tickets,
        and spending a significant amount of time explaining both technical processes and non-technical aspects relevant to the project."</p>
    </div>


  </div>

<!--</ul>-->

